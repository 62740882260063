import * as React from 'react'
import Slider2 from '../components/Slider2'
import styled from 'styled-components'
import { breakpoint } from '../styles/variables'

const Title = styled.h2`
  text-align: center;

  @media ${breakpoint.md} {
    text-align: left;
    margin-top: 0;
  }
`


const Places = ({title, description } ) => {

  return (
    <>
      <Title>Místa, která jsem navštívila</Title>
      <div><Slider2/></div>
    </>
  )
}

export default Places

