import React from "react";
import { useSiteMetadata } from "../hooks/useSiteMetadata";

export const PageHead = ({ title, description, pathname, children }) => {
    const {
        title: defaultTitle,
        description: defaultDescription,
        siteUrl,
    } = useSiteMetadata();

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        url: `${siteUrl}${pathname || ``}`,
    };

    return (
        <>
            <title>{seo.title}</title>
            <meta name="description" content={seo.description} />
            {/* <meta name="image" content={seo.image} /> */}
            {/* <link rel="icon" href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='0.9em' font-size='90'>👤</text></svg>" /> */}
            {/* <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="e1544ab2-6d93-4353-acb5-83ffb7ee76c5" data-blockingmode="auto" type="text/javascript"></script> */}
            <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-1K8FHQW6K3"
            ></script>
            <script>{`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-1K8FHQW6K3');
      `}</script>
            {children}
        </>
    );
};
