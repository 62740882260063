import * as React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { places } from '../content/content'
import styled from 'styled-components'
import { breakpoint, colors } from '../styles/variables'
import { StaticImage } from "gatsby-plugin-image"


const Wrapper = styled.div`
  padding: 1rem 1rem 6rem 1rem;
  //background-color: white;
  background-color: #fffef8;
  border-radius: 5px;
  box-shadow: 1px 1px 12px 2px #bfbfbf4f;
  position: relative;


  p {
    opacity: 0;
  }

  &:hover {

    .polaroid-footer {
      height: calc(100% + 6px);
      transform: none;

    }

    p {
      opacity: 1;
    }

    .h3 {
      margin-top: 1rem;
    }
  }

`

const Title = styled.h3 `
  margin-bottom: 0;
`

const Footer = styled.div`
  //background-color: #ffffffd4;
  background-color: #fffef8db;
  position: absolute;
  width: calc(100% + 6px);
  bottom: -3px;
  left: -3px;
  right: -3px;
  overflow: hidden;
  transition: all .6s ease;
  height: 4rem;
  transform: translateY(4rem);
  padding: 0 1rem;

  p{
      font-size: 0.875rem;
  }

`

const ContentWrapper = styled.div`
  position: relative;
`

const StyledSplide = styled(Splide)`
  margin: 0 auto 5rem;

    .splide__slide {
        max-width: 400px;
    }

    .splide__pagination {

        bottom: -2.5em;
    }

        .splide__arrow svg {
            fill: #fff;
        }

        .splide__arrow--prev {
            left: -1em;
            @media ${breakpoint.lg} {
            left: -3em;
        }}

        .splide__arrow--next {
            right: -1em;

            @media ${breakpoint.lg} {
            right: -3em;
        }}

        .splide__pagination__page.is-active{
            background: ${colors.primary}
        }

        .splide__track {
            padding: 0.5rem !important;
        }
`

const SplideWrapper = styled.div`

`

const Slider = () => {
    return (

        <SplideWrapper>
            <StyledSplide  options={ {
                rewind: true,
                gap   : '1rem',
                perPage: 1,
                mediaQuery: 'min',
                //autoWidth: false,
                arrows: false,
                breakpoints: {
                    640: {
                        perPage: 2,
                        //perMove: 1,
                    },
                    1024: {
                        perPage: 3,
                        arrows: true,
                    },
              }

            } }
            >
              <SplideSlide>
                    <div>
                        <Wrapper>
                            <ContentWrapper>
                                <StaticImage src="../assets/images/destinations/austria_1.jpg" alt="" />
                                <Footer className="polaroid-footer">
                                    <Title className="content-title">{places.austria.title}</Title>
                                    <p>{places.austria.description}</p>
                                </Footer>
                            </ContentWrapper>
                         </Wrapper>
                    </div>
              </SplideSlide>
              <SplideSlide>
                    <div>
                        <Wrapper>
                            <ContentWrapper>
                                <StaticImage src="../assets/images/destinations/bulgaria_1.jpg" alt="" />
                                <Footer className="polaroid-footer">
                                    <Title className="content-title">{places.bulgaria.title}</Title>
                                    <p>{places.bulgaria.description}</p>
                                </Footer>
                            </ContentWrapper>
                         </Wrapper>
                    </div>
              </SplideSlide>
              <SplideSlide>
                    <div>
                        <Wrapper>
                            <ContentWrapper>
                                <StaticImage src="../assets/images/destinations/cambodia_1.jpg" alt="" />
                                <Footer className="polaroid-footer">
                                    <Title className="content-title">{places.cambodia.title}</Title>
                                    <p>{places.cambodia.description}</p>
                                </Footer>
                            </ContentWrapper>
                         </Wrapper>
                    </div>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/canaryIslands_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.canaryIslands.title}</Title>
                                <p>{places.canaryIslands.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/crete_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.crete.title}</Title>
                                <p>{places.crete.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/denmark_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.denmark.title}</Title>
                                <p>{places.denmark.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/france_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.france.title}</Title>
                                <p>{places.france.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/germany_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.germany.title}</Title>
                                <p>{places.germany.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/holand_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.holand.title}</Title>
                                <p>{places.holand.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/italy_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.italy.title}</Title>
                                <p>{places.italy.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/israel_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.israel.title}</Title>
                                <p>{places.israel.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/latvia_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.latvia.title}</Title>
                                <p>{places.latvia.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/lithuania_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.lithuania.title}</Title>
                                <p>{places.lithuania.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/northMacedonia_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.northMacedonia.title}</Title>
                                <p>{places.northMacedonia.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/norway_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.norway.title}</Title>
                                <p>{places.norway.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/poland_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.poland.title}</Title>
                                <p>{places.poland.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/rhodes_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.rhodes.title}</Title>
                                <p>{places.rhodes.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/serbia_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.serbia.title}</Title>
                                <p>{places.serbia.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/sriLanka_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.sriLanka.title}</Title>
                                <p>{places.sriLanka.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/thailand_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.thailand.title}</Title>
                                <p>{places.thailand.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/ukraine_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.ukraine.title}</Title>
                                <p>{places.ukraine.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/usaEast_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.usaEast.title}</Title>
                                <p>{places.usaEast.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/spain_5.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.spain2.title}</Title>
                                <p>{places.spain2.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/england_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.england.title}</Title>
                                <p>{places.england.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/wales_2.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.wales.title}</Title>
                                <p>{places.wales.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/portugal_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.portugal.title}</Title>
                                <p>{places.portugal.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/croatia_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.croatia.title}</Title>
                                <p>{places.croatia.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/belgium_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.belgium.title}</Title>
                                <p>{places.belgium.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide>
              {/* <SplideSlide>
                    <Wrapper>
                        <ContentWrapper>
                            <StaticImage src="../assets/images/destinations/hungary_1.jpg" alt="" />
                            <Footer className="polaroid-footer">
                                <Title className="content-title">{places.hungary.title}</Title>
                                <p>{places.hungary.description}</p>
                            </Footer>
                        </ContentWrapper>
                     </Wrapper>
              </SplideSlide> */}

            </StyledSplide>
        </SplideWrapper>
    )
}

export default Slider