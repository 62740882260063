import * as React from 'react'
import styled from 'styled-components'
import { breakpoint, colors } from '../styles/variables'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import Container from './Container'


const Wrapper = styled.header`
  height: 33.75rem;
  color: white;
  max-width: 1920px;
  margin: 0 auto;
  display: grid;

  @media ${breakpoint.lg} {
    height: 43.75rem;
  }

  .banner-image {
    grid-area: 1/1;
  }
`

const BannerContent = styled.div`
  position: relative;
  grid-area: 1/1;
  background: linear-gradient(0deg, rgba(0,0,0,0.9009978991596639) 0%, rgba(0,0,0,0.6460959383753502) 50%, rgba(0,0,0,0) 100%);
  display: grid;
  padding: 1.5rem;

`
const Title = styled.h1`
  font-size: 3rem;
  font-weight: 400;
  text-transform: uppercase;
  margin: auto 0 0 -3px;
  letter-spacing: 0.2em;

  &::first-letter {
    font-size: 120%;
  }

  @media ${breakpoint.lg} {
    font-size: 5rem;
  }
`

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin: 1rem 0;
  font-weight: 300;

  @media ${breakpoint.md} {
    font-size: 2rem;
  }

  @media ${breakpoint.lg} {
    font-size: 2rem;

  }
`

const SubtitleSecondary = styled.p`
  display: none;

  @media ${breakpoint.md} {
    display: block;
    font-size: 1.25rem;
    margin-top: 0.2em;
    margin-top: 0.2em;
  }
`

const BannerText = styled(Container)`
  margin-top: auto;
  margin-bottom: 1.5rem;
  display: grid;
  width: 100%;

  @media ${breakpoint.md} {
    margin-bottom: 4rem;
  }

  @media ${breakpoint.lg} {

  }

`

const Cta = styled(Link)`
  display: block;
  text-decoration: none;
  color: white;
  text-align: center;
  padding: 1rem 1.5rem;
  border: 0.5px solid #ffffff;
  justify-self: start;
  margin-top: 3rem;
  transition: all 0.3s ease;
  //border-radius: 2px;

  &:hover {
    //background-color: #ffffff3d;
    border-color: ${colors.primary};
    color: ${colors.primary};
  }

  @media ${breakpoint.lg} {
  justify-self: end;
  margin-top: 0;
  }
`

const Banner = ({children, className}) => {
  return (
    <Wrapper className={className}>
      <StaticImage className="banner-image"  alt="" src={"../assets/images/cover.jpg"}/>
      <BannerContent>
        <BannerText>
          <Title>Cestu-j</Title>
          <Subtitle>Když si chcete dovolenou prostě užít</Subtitle>
          <SubtitleSecondary>Nechte se unášet zážitky z cestování</SubtitleSecondary>
          <Cta to='/sluzby'>Itineráře přesně vám na míru</Cta>
        </BannerText>
      </BannerContent>
    </Wrapper>
  )
}

export default Banner