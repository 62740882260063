import * as React from 'react'
import styled from 'styled-components'
import { breakpoint, colors } from '../styles/variables'
import Container from '../components/Container'
import Banner from '../components/Banner'
import { Link } from 'gatsby'
import Places from '../organisms/Places'
import About from '../organisms/About'
import { PageContent } from '../components/PageContent'
import { PageHead } from '../components/PageHead'


const StyledContainer = styled(Container)`
  //display: grid;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
`

const Perex = styled.p`
  text-align: center;
  font-size: 1rem;
  margin: 0 auto 1rem auto;

  @media ${breakpoint.md} {
    padding-bottom: 1rem;
    font-size: 1.25rem;
    max-width: 80%;
  }

  @media ${breakpoint.lg} {
    padding: 1rem 0;
  }

`

const PerexLink = styled(Link)`
  text-decoration: none;
  color: #000;
  text-align: center;
  border: 2px solid #000;
  padding: 1em 1.5em;
  transition: all 0.3s ease;
  cursor: pointer;
  font-weight: 500;
  margin: 2em auto 0;

  @media ${breakpoint.md} {
    width: unset;
  }

  &:hover {
    border-color: ${colors.primary};
    color: ${colors.primary};
  }
`

const PlacesSection = styled.section`
  @media ${breakpoint.md} {
    padding-bottom: 5rem;
  }
`

const Separator = styled.span`
    width: 120px;
    height: 3px;
    background-color: ${colors.primary};
    margin: 5rem auto 2rem;
    display: none;

    @media ${breakpoint.md} {
      display: block;
    }

    @media ${breakpoint.lg} {
      margin: 6rem auto 4rem;
    }
`



const IndexPage = () => {
  return (
    <>
    <Banner></Banner>
      <PageContent>
        <StyledContainer>

          <Perex>
            Plánujete dovolenou, na které si chcete především odpočinout? Nebalte si do kufru starosti ani otázky,
              kde budete každý den jíst nebo jak se dostanete z letiště na hotel. Raději si přibalte itineráře na míru,
              které budou vaším průvodcem v dané zemi.
          </Perex>
          <PerexLink to="/sluzby">Rády vám je sestavíme</PerexLink>
          <Separator></Separator>
          <section id="o-mne">
            <About />
          </section>

          {/* <PlacesSection id="galerie">
            <Places />
          </PlacesSection> */}
        </StyledContainer>
      </PageContent>

    </>
  )
}

export default IndexPage

export const Head = () => (
  <>
    <meta name="google-site-verification" content="GXiwgo1z5froDDu4wAFUVhMY7pKhPGtakCNSc5tJCQc" />
    <PageHead />
  </>
)